import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useUsersList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'firstName', sortable: true },
    { key: 'lastName', sortable: true },
    { key: 'email', sortable: true },
    { key: 'role', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions', sortable: false },
  ];
  const sortBy = ref('id');
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const typeFilter = ref(null);
  const accountFilter = ref(null);
  const isSortDirDesc = ref(true);
  const loading = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([perPage, currentPage, searchQuery, typeFilter, accountFilter], () => {
    refetchData();
  });

  const fetchUsers = (ctx, callback) => {
    if (sortBy.value == '') {
      sortBy.value = 'id';
    }

    loading.value = true;

    store
      .dispatch('user/fetchUsers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        rank: typeFilter.value,
        account: accountFilter.value,
      })
      .then((response) => {
        callback(response.data.data);
        totalUsers.value = response.data.total;
        loading.value = false;
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = (type) => {
    if (type === 'A') return 'success';
    if (type === 'P') return 'danger';

    return 'primary';
  };

  const resolveUserTypeVariant = (type) => {
    if (type === 'supermanager') return 'danger';
    if (type === 'admin') return 'danger';

    if (type === 'user') return 'primary';

    return 'primary';
  };

  return {
    fetchUsers,

    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    loading,

    resolveUserStatusVariant,
    resolveUserTypeVariant,
    refetchData,

    // Extra Filters
    typeFilter,
    accountFilter,
  };
}
